@import './Index.less';

// The mobile navigation window.
@keyframes slideDown {
    from {
        opacity: 0;
        height: 0px;
    }
    to {
        opacity: 1;
        height: 400px;
    }
}
.mobile-nav {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 3;
    width: 100%;
    height: 100%;
    padding: 30px 0px;
    box-sizing: border-box;
    overflow: hidden;
    background-color: white;
    animation: slideDown 0.3s sin(360);
    background: url('../images/LooseLeafBackground.svg');

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .mobile-nav-image{
      width: 100px;
      height: auto;
    }

    // The color buttons should not turn white
    // since the bubbles are hidden.
    .color-button {
        font-size: 36px;
        margin-top: 30px;
        font-family: 'Nanum Pen Script';

        &:hover {
            color: black;
            font-weight: normal;
        }

        &:active {
            font-weight: bolder;
        }
    }
}
