@import './Index.less';
@import './OrganizerCell.less';

.about__organizers{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.informationSection{
  margin-bottom: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.sponsors{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  img{
    margin-top: 40px;
    width: 50%;
    height: auto;
  }
  #upperlineLogo{
    width: 100%;
    height: auto;
  }
  #appleLogo{
    width: 25%;
    height: auto;
    margin-top: 20px;
  }
}

// Media Queries:
@media not screen and (min-width: 768px) {
    .about__organizers{
      flex-direction: column;
    }
    h2{
      text-align: center;
    }
}
