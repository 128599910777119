@import './Index.less';

.organizerCell {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;

  img {
      width: 130px;
      height: auto;
      border-radius: 100%;
      background-color: lightgray;
  }

  p{
    text-align: center;
    margin-top: 10px;
  }
}
