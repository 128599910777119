@import './Index.less';

.mainLastYear{
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.carouselSection{
  padding: 20px;
  margin: 100px 0px;
  border: 1px #333 solid;
  border-radius: 5px;
}

.yearHeader{
  text-align: center;
}

.carouselText{
  color: white;
  background-color: #000;
  padding: 10px 20px;
  border-radius: 5px;
}

h3.carouselText{
  display: inline-block;
}

.resizeImage{
  width: 1000px;
  height: auto;
}

.dividerBubbleLastYear{
  width: 50%;
  margin: 25px 0px;
}

// Media Queries:
@media not screen and (min-width: 768px) {
  .dividerBubble{
    width: 100%;
    margin: 10px 0px;
  }
  .resizeImage{
    width: 100%;
    height: auto;
  }
  .carouselText{
    display: none;
  }
  h3.carouselText{
    display: none;
  }
  .carouselSection{
    margin: 25px 0px;
    border: none;
  }
  .dividerBubbleLastYear{
    width: 75%;
    margin: 10px 0px;
  }
}
