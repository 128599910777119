.app{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.root{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.main{
  max-width: 540px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

section{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.pageTitle{
  width: 100%;
  position: relative;
  h1{
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;
  }
  #bubbleyBackground{
    position: relative;
    z-index: -100;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.pageTitle h1{
  font-family: 'Nanum Pen Script', cursive;
  font-size: 144px;
  font-weight: normal;
}

h2{
  margin: 60px 0px 0px 0px;
  font-family: 'Rubik', sans-serif;
  font-size: 36px;
  font-weight: normal;
}

h3{
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0px;
}

p, ul, li{
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: normal;
}

.dividerBubble{
  width: 100%;
  height: auto;
  margin-bottom: 100px;
}

@media not screen and (min-width: 768px) {
  .main{
    width: 100%;
    padding: 0px 50px;
    box-sizing: border-box;
  }
  .dividerBubble{
    margin-bottom: 50px;
  }
  .pageTitle{
    margin-bottom: 25px;
    h1{
      font-size: 60px;
      line-height: 60px;
    }
  }
}
