@import './Index.less';

footer {
    width: 100%;
    margin: 100px 0px 0px 0px;
    padding: 25px 40px;
    box-sizing: border-box;
    text-align: center;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #D5EFF6;

    img {
      margin: 0px;
      width: 70px;
      height: auto;
    }

    .footer-text {
      font-size: 14px;
      font-family: 'Noto Sans';
      margin: 0px;
      text-align: center;
    }

    button{
      height: 40px;

      &:hover, &:focus {
          background-color: #AB6503;
          cursor: pointer;
      }
    }
}


// Media Queries:
@media not screen and (min-width: 768px) {
    footer {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        button{
          margin-top: 50px;
        }

        img{
          display: none;
        }
    }
}
