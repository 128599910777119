// Variables:
@primary: #009ED2;
@red: #F26C72;
@blue: #39D4E1;//#009ED2;
@yellow: #F99C1C;
@green: #6CCACD;

.common-font {
    font-size: 18px;
    line-height: 21px;
    font-style: normal;
    font-family: 'Rubik', sans-serif;
    font-weight: normal;
}
.header-font {
    font-size: 110px;
    line-height: 180px;
    font-style: normal;
    font-weight: normal;
    font-family: 'Nanum Pen Script';
}


// Buttons:
.primary-button {
    border: none;
    outline: none;
    padding: 8px 20px;
    .common-font();
    color: white;
    cursor: pointer;
    background: none;
    border-radius: 4px;
    text-decoration: none;
    background-color: #237A9F;

    // Hover and click states.
    &:hover {
        background-color: #AB6503;
    }
    &:active {
        background-color: #AB6503;
    }
    &:focus {
        background-color: #AB6503;
    }
}


// The entire app has the loose leaf paper background.
.app {
    width: 100%;
}
html, body {
    margin: 0px;
    padding: 0px;
}


// Media Queries:
@media not screen and (min-width: 768px) {
    // Primary buttons should not have a hover state.
    .primary-button {
        &:hover { background-color: #AB6503; }
        &:active { background-color: darken(@primary, 10%); }
    }
}
