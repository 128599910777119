.collectionView {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    white-space: nowrap;
    height: fit-content;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    & > div {
        text-decoration: none;
    }
}

#popupList {
    overflow: scroll;
    white-space: pre-wrap;

    &::-webkit-scrollbar {
        display: none;
        visibility: hidden;
    }
}