@import './Index.less';

.faq__h2, .faq__p{
  width: 100%;
  text-align: left;
}

.QandA{
  width: 100%;
  margin-bottom: 50px;
}

// Media Queries:
@media not screen and (min-width: 768px) {

}
