@import './Index.less';

#schedule-image-1{
  width: 150%;
  height: auto;
}

.thisYear__h3{
  width: 100%;
  text-align: left;
}

.section__leader{
  width: 100%;
  text-align: left;
  margin: 5px 0px;
}

.section__description{
  width: 100%;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 100px;
}

#workshopSessionTable{
  width: 100%;
  margin-bottom: 100px;
  font-family: 'Poppins', sans-serif;
  tr{
    width: 100%;
    th, td{
      background-color: #D8F3F8;
      width: 50%;
      text-align: left;
      vertical-align: top;
      padding: 10px;
    }
  }
  #twoSessionWorkshop{
    text-align: center;
  }
}

// Media Queries:
@media not screen and (min-width: 768px) {
  #schedule-image-1{
    width: 100%;
    height: auto;
  }
}
