@import './Index.less';
@import './OrganizerCell.less';

.pageTitle .logo{
  width: 200px;
  height: auto;
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -100px;
  padding: 0;
  text-align: center;
}

.home{
  justify-content: center;
  text-align: center;
}

.scribbleDescription{
  margin-top: 0px;
  font-family: 'Rubik', sans-serif;
  font-size: 18px;
  font-weight: bold;
}
p{
  text-align: center;
}
section{
  margin-bottom: 50px;
}
.dividerBubble{
  margin-bottom: 50px;
}
.marginOnBottom{
  margin-bottom: 50px !important;
}
.numberFont{
  font-family: 'Rubik', sans-serif;
  font-size: 35px;
}
#attendees{
  color: #287FA4;
}
#schools{
  color: #AC6406;
}
#workshops{
  color: #268281;
  margin-bottom: 10px;
}
.photo{
  width: 100%;
}
.schoolList{
  margin: 0px;
  text-align: center;
  margin-bottom: 10px;
  list-style-type: none;
  padding: 0px;
}
.workshopList{
  margin: 0px;
  text-align: left;
  margin-bottom: 10px;
}
.photoLink{
  font-family: 'Rubik', sans-serif;
  font-size: 25px;
  font-weight: normal;
}

// Media Queries:
@media not screen and (min-width: 768px) {
  .pageTitle .logo{
    width: 50px;
    margin-left: -25px;
  }
  h2{
    margin-top: 25px;
    text-align: center;
    font-size: 30px;
  }
  .numberFont{
    font-family: 'Rubik', sans-serif;
    font-size: 25px;
    margin: 10px 0px;
  }
  .infoPiece{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  #groupPhoto{
    width: 100%;
  }
  #workshopPhoto{
   width: 100%;
  }
}
