@import './Index.less';

// The navigation bar component.
.navigation-bar {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 40px;
    box-sizing: border-box;
    z-index: 100;

    // Hide the hamburger menu on desktop.
    span[class="fa fa-bars"], span[class="fas fa-times"] {
        position: fixed;
        top: 25px;
        display: none;
        z-index: 100;
        cursor: pointer;
        font-size: 28px;
        margin-left: 10%;
        color: rgb(83, 83, 83);
    }

    .skip-nav{
      left:-999px;
      position:absolute;
      top:auto;
      width:1px;
      height:1px;
      overflow:hidden;
      z-index:-999;

      &:focus, &:active{
        width: 20%;
        height: auto;
        color: black;
        background-color: pink;
        left: 0;
        top: 100px;
        overflow: auto;
        padding: 25px;
        text-align: center;
        font-size: 24px;
        font-family: 'Rubik', sans-serif;
        z-index: 999;
        text-decoration: none;
      }
    }

    .nav__logo {
        width: 70px;
        height: auto;
        background-color: white;
        padding: 5px;
        border-radius: 100px;
    }

    .nav__button {
        text-decoration: none;
        margin-left: 50px;
        font-family: 'Rubik', sans-serif;
        font-size: 18px;
        color: #333;
        background-color: rgba(255,255,255,0.9);
        padding: 5px 10px;
        border-radius: 10px;
    }
    #homeButton{
      &:hover, &:focus{
        background-color: #90CBE4;
      }
    }
    #aboutButton{
      &:hover, &:focus{
        background-color: #EDA1A2;
      }
    }
    #lastYearButton{
      &:hover, &:focus{
        background-color: #FCC57E;
      }
    }
    #faqButton{
      &:hover, &:focus{
        background-color: #68D3D2;
      }
    }

    .nav__signupButton {
        color: white;
        background-color: #237A9F;
        border: none;
        padding: 8px 20px;
        border-radius: 4px;
        &:hover, &:focus {
            background-color: #AB6503;
            cursor: pointer;
        }
    }
}


// Media Queries:
@media not screen and (min-width: 768px) {
    .navigation-bar {
        text-align: left;

        // Only show the hamburger menu on mobile.
        span[class="fa fa-bars"], span[class="fas fa-times"] {
            display: block;
            position: fixed;
            left: 0px;
            background-color: rgba(255, 255, 255, 0.5);
            padding: 5px;
            border-radius: 5px;
        }

        // Hide everything else on mobile.
        & .nav__logo {
            display: none;
        }
        & .nav__button {
            display: none;
        }
    }

}
